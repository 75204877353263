
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonButtons, IonInput  } from '@ionic/vue';


export default  {
    name: 'YeastBallCalculator',
    components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonInput  },
    data() {
        return {
            yeastWeight: 0.1
        }
    },
    computed: {
        yeastBallDiameter: function() {
            return Math.round(Math.pow(
                ((0.75 * this.yeastWeight / (42/36)) / Math.PI),
                (1/3)
            ) * 20);
        }
    },
    methods: {

    }
}
